import request from "./request";

/**
 * 分享领券
 */

/**
 * 查询
 */
export const list = data => request.get("/share/", { params: data });

/**
 * 查询详情
 */
export const detail = id => request.get("/share/detail", { params: { id } });

/**
 * 创建/编辑
 */
export const createOrUpdate = data => request.post("/share/", data);

/**
 * 切换状态
 */
export const updateStatus = id => request.post("/share/status", { id });

/**
 * 删除
 */
export const del = id => request.post("/share/del", { id });

/**
 * 查询规则
 */
export const getRule = () => request.get("/share/rule");

/**
 * 编辑规则
 */
export const updateRule = data => request.post("/share/rule", data);
