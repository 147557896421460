<template>
  <!-- 商品选择器 -->
  <a-modal
    title="选择商品"
    :visible.sync="visible"
    width="80%"
    @ok="
      visible = false;
      selectedRowKeys = [];
    "
    @cancel="
      visible = false;
      selectedRowKeys = [];
    "
  >
    <div class="search-tool">
      <a-form layout="inline" :form="form" @submit="handleSearch">
        <a-form-model-item v-if="!onlySelfProduct" label="商品库">
          <a-select v-model="source" placeholder="请选择">
            <a-select-option :value="1">自营商品</a-select-option>
            <a-select-option :value="2">带货商品</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-item label="分类">
          <a-tree-select
            style="min-width:180px"
            show-search
            allow-clear
            v-model="filters.cid"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="categoryTree"
            placeholder="请选择"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>

        <a-form-item label="商品标题">
          <a-input
            v-decorator="['title']"
            :maxLength="20"
            allow-clear
            placeholder="输入商品名称"
            v-model="filters.title"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">
            搜索
          </a-button>
          <a-button
            html-type="reset"
            @click="filters = { title: '', cid: '' }"
            :style="{ marginLeft: '8px' }"
          >
            复位
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table
      ref="productTable"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :rowSelection="{
        type: multiple ? 'checkbox' : 'radio',
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys
      }"
      :loading="loading"
    >
      <img
        slot="img"
        slot-scope="url"
        style="width:78px;height:78px"
        :src="url"
      />
      <span slot="price" slot-scope="text">￥{{ text }}</span>
    </a-table>

    <div slot="footer" style="display:flex;justify-content: space-between;">
      <div>
        <a-button :loading="loading" @click="loadData">
          <a-icon type="sync" />
          同步商品库</a-button
        >
      </div>
      <div>
        <a-button v-if="multiple" type="primary" @click="save">保存</a-button>
        <a-button @click="visible = false">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const DecoSvc = require("@/service/decoration");

import categoryMixin from "@/mixins/category";

const columns = [
  {
    title: "主图",
    dataIndex: "img",
    key: "img",
    width: 100,
    scopedSlots: { customRender: "img" }
  },
  {
    title: "商品ID",
    dataIndex: "id",
    key: "id",
    width: 100
  },
  {
    title: "商品标题",
    dataIndex: "title",
    key: "title",
    ellipsis: true
  },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
    width: 160,
    align: "right",
    scopedSlots: { customRender: "price" }
  }
];

export default {
  name: "ProductDialog",
  data() {
    return {
      form: this.$form.createForm(this, { name: "form_search" }),

      columns,
      loading: false,
      visible: false,

      filters: { title: "", cid: "" },

      currentRow: null,
      multipleSelection: [],
      selectedRowKeys: [],

      source: 1
    };
  },

  props: {
    // 是否多选
    multiple: Boolean,
    // 仅显示自营商品库
    onlySelfProduct: Boolean
  },

  mixins: [categoryMixin],

  computed: {
    ...mapState({
      productList: state => state.productList,
      productList2: state => state.productList2
    }),

    list() {
      const { source, filters, productList, productList2 } = this;
      let { title, cid } = filters;
      title = title ? title.toLowerCase() : "";

      let list = source === 1 ? productList : productList2;
      if (title) {
        list = list.filter(x => x.title.toLowerCase().indexOf(title) >= 0);
      }
      if (cid) {
        // 汇总下级ID
        let cidList = this.categoryList
          .filter(x => x.fid === cid)
          .map(x => x.id);
        cidList.push(cid);

        list = list.filter(
          x => (x.cid || []).filter(z => cidList.includes(z)).length > 0
        );
      }

      return list;
    }
  },

  methods: {
    ...mapMutations(["setProductList", "setThirdProductList"]),

    async loadData() {
      try {
        this.loading = true;

        const { list } = await DecoSvc.loadProductList();
        this.setProductList(list);

        // 带货商品
        const res = await DecoSvc.loadThirdProductList();
        this.setThirdProductList(res.list);

        this.loading = false;
      } catch (err) {
        console.error(err);
        this.$message.error("加载商品出错，错误：" + err.message);

        this.loading = false;
      }
    },

    // 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;

      if (this.multiple) {
        this.multipleSelection = selectedRows;
      } else {
        // 自动提交
        this.currentRow = selectedRows[0];
        this.save();
      }
    },

    show() {
      // todo 默认选中
      // this.setSelection(rows);

      this.visible = true;
    },

    save() {
      if (this.multiple && this.multipleSelection.length <= 0) {
        this.$message.info("你没有选中任何商品");
        return;
      }

      this.$emit(
        "selected",
        this.multiple ? this.multipleSelection : this.currentRow
      );

      this.visible = false;
      this.selectedRowKeys = [];
    },

    // 搜索
    handleSearch(e) {
      e.preventDefault();
      const values = this.form.getFieldsValue();
      let { title } = values;
      this.filters.title = title || "";
    }
  }
};
</script>

<style lang="scss">
.search-tool {
  padding-bottom: 10px;
}
</style>
