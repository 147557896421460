<template>
  <div class="app-logout">
    <a-result
      status="403"
      title="请重新登录"
      sub-title="请通过微信小商店服务市场内打开使用"
    >
      <template #extra>
        <div>
          <a-button @click="gotoMiniShop" type="primary">
            前往微信小商店
          </a-button>
        </div>
        <div style="margin-top:50px;">
          <a-button @click="gotoFix" type="link">
            登录故障请点这里
          </a-button>
        </div>
      </template>
    </a-result>
  </div>
</template>

<script>
import { logout } from "@/service/auth";

export default {
  mounted() {
    logout();
  },

  methods: {
    gotoMiniShop() {
      window.location.href = "https://shop.weixin.qq.com";
    },

    gotoFix() {
      window.location.href = "https://api.xinsailei.com/msa/wx";
    }
  }
};
</script>

<style>
.app-logout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
