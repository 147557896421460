import request from "./request";

/**
 * 页面
 */

/**
 * 查询
 */
export const list = () => request.get("/p/");

/**
 * 加载数据
 */
export const getData = id => request.get(`/p/get?id=${id}`);

/**
 * 新建
 */
export const add = (templateId = 0) => request.post("/p/new", { templateId });

/**
 * 复制
 */
export const copy = id => request.post("/p/copy", { id });

/**
 * 删除
 */
export const del = id => request.post("/p/del", { id });

/**
 * 编辑
 */
export const edit = (id, res) => request.post("/p/save", { id, res });

/**
 * 改名
 */
export const updateName = (id, name) =>
  request.post("/p/updateName", {
    id,
    name
  });

/**
 * 设为首页
 */
export const setActive = id => request.post("/p/active", { id });
