/**
 * 工具自动生成ID
 */
export const generateKey = tool => {
  // const id = Date.now();
  const id = randomKey();
  tool.key = id;

  if (tool.res) {
    if (tool.res.rows) {
      tool.res.rows.forEach((x, i) => {
        x.key = id + i;
      });
    }
    if (tool.res.cards) {
      tool.res.cards.forEach((x, i) => {
        x.key = id + i;

        if (x.rows) {
          x.rows.forEach((r, n) => {
            r.key = id + n;
          });
        }
      });
    }
  }

  return tool;
};

/**
 * 生成随机数
 */
export const randomKey = () => {
  return Math.random()
    .toString(16)
    .slice(2);
};

export const getImageUrl = iconName => {
  return require("@/assets/ic-" + iconName + ".png");
};

export const hexToRGBA = (hex, alpha) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${alpha})`
    );
  }
  throw new Error("Bad Hex");
};

export const invertHex = hex => {
  return (Number(`0x1${hex}`) ^ 0xffffff)
    .toString(16)
    .substr(1)
    .toUpperCase();
};

export function hexToRgb(color) {
  let sColor = color.toLowerCase();
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6}|[0-9a-fA-f]{8})$/;
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = "#";
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }

    // rgba
    if (sColor.length === 9) {
      const alpha = parseInt("0x" + sColor.slice(7));
      if (alpha < 89) {
        return [255, 255, 255];
      }
    }

    let result = [];
    for (let i = 1; i < 7; i += 2) {
      result.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    return result;
  }
  return [];
}

export function isColorDark(color) {
  const rgb = hexToRgb(color);
  const val = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return val < 168;
}

/**
 * 秒杀按钮文字
 */
export const TIMESALE_BTN_TEXT = {
  start: "查看详情",
  end: "已结束",
  counting: "去抢购",
  "": "去抢购"
};

/**
 * 优惠券单位
 */
export function getCouponUnit(type) {
  return [1, 101, 3, 103].includes(type) ? "折" : "元";
}

/**
 * 优惠券描述
 */
export function getCouponTips(item) {
  const type = item.type > 100 ? item.type - 100 : item.type;
  const TIPS = {
    1: "满xx件yy折",
    2: "满xx减yy",
    3: "yy折优惠券",
    4: "yy元直减券"
  };
  return TIPS[type].replace("xx", item.num1).replace("yy", item.num2);
}
