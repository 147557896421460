import Vue from "vue";
// import Antd from "ant-design-vue";

import App from "./App.vue";
import router from "./Router.js";
import store from "./store";

// Vue.use(Antd);

import "@/utils/button.scss";

// 商品选择器
import ProductDialog from "./components/product.vue";
Vue.component(ProductDialog.name, ProductDialog);

// 拼团选择器
import PinDialog from "./components/pin.vue";
Vue.component(PinDialog.name, PinDialog);

// 砍价选择器
import KanDialog from "./components/kan.vue";
Vue.component(KanDialog.name, KanDialog);

// 秒杀选择器
import LimitedDiscountDialog from "./components/time-sale.vue";
Vue.component(LimitedDiscountDialog.name, LimitedDiscountDialog);

// 有礼选择器
import ShareDialog from "./components/share.vue";
Vue.component(ShareDialog.name, ShareDialog);

// 抽奖选择器
import PrizeDialog from "./components/prize.vue";
Vue.component(PrizeDialog.name, PrizeDialog);

// 热区编辑器
import HotzoneDialog from "./components/hotzone.vue";
Vue.component(HotzoneDialog.name, HotzoneDialog);

// BUG跟踪
// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     Vue,
//     dsn:
//       "https://4477e70f017945c68b2c5b6e6da56741@o576409.ingest.sentry.io/5729956",
//     integrations: [new Integrations.BrowserTracing()],
//     tracingOptions: {
//       trackComponents: true
//     },
//     tracesSampleRate: 1.0,

//     release: process.env.VUE_APP_VERSION || "1.0.0"
//   });
// }

// 百度统计
// import ba from "vue-ba";
// Vue.use(ba, "057d06688117ca89c07938666dfbc1f4");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
