import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      categoryList: state => state.categoryList
    }),

    categoryTree() {
      let tree = [];
      for (const row of this.categoryList.filter(x => x.level === 1)) {
        const children = this.categoryList
          .filter(x => x.fid === row.id)
          .map(x => ({
            title: x.name,
            value: x.id,
            key: x.id,
            children: []
          }));

        tree.push({
          title: row.name,
          value: row.id,
          key: row.id,
          children
        });
      }

      return tree;
    }
  }
};
