// import Vue from "vue";
import VueRouter from "vue-router";

import Index from "./pages/Index.vue";
import Login from "./pages/Login.vue";
import Logout from "./pages/Logout.vue";
import Help from "./pages/Help.vue";

const auth = require("@/service/auth");

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/help",
    name: "Help",
    meta: { requiresAuth: true },
    component: Help,
  },
  {
    path: "/decoration/home",
    name: "DecorationHome",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "decoration-home" */ "./pages/DecorationHome.vue"
      ),
  },
  {
    path: "/decoration/category",
    name: "DecorationCategory",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "decoration-category" */ "./pages/DecorationCategory.vue"
      ),
  },
  {
    path: "/page",
    name: "PageList",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "page-list" */ "./pages/PageList.vue"),
  },
  {
    path: "/page/detail/:id",
    name: "PageDetail",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "page-detail" */ "./pages/PageDetail.vue"),
  },
  {
    path: "/template",
    name: "Template",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "template" */ "./pages/Template.vue"),
  },
  {
    path: "/client",
    name: "Client",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "client" */ "./pages/Client.vue"),
  },
  {
    path: "/client/tag",
    name: "Tag",
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "tag" */ "./pages/Tag.vue"),
  },
  {
    path: "/client/coupon",
    name: "ClientCoupon",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "user-coupon" */ "./pages/UserCoupon.vue"),
  },

  // 拼团
  {
    path: "/sale/pin",
    name: "Pin",
    component: () => import(/* webpackChunkName: "pin" */ "./pages/Pin.vue"),
  },
  {
    path: "/sale/pin/edit",
    name: "PinEdit",
    component: () =>
      import(/* webpackChunkName: "pin-edit" */ "./pages/PinEdit.vue"),
  },
  {
    path: "/sale/pin/order",
    name: "PinOrder",
    component: () =>
      import(/* webpackChunkName: "pin-order" */ "./pages/PinOrder.vue"),
  },

  // 砍价
  {
    path: "/sale/kan",
    name: "Kan",
    component: () => import(/* webpackChunkName: "kan" */ "./pages/Kan.vue"),
  },
  {
    path: "/sale/kan/edit",
    name: "KanEdit",
    component: () =>
      import(/* webpackChunkName: "kan-edit" */ "./pages/KanEdit.vue"),
  },
  {
    path: "/sale/kan/order",
    name: "KanOrder",
    component: () =>
      import(/* webpackChunkName: "kan-order" */ "./pages/KanOrder.vue"),
  },

  // 弹窗广告
  {
    path: "/ad/pop",
    name: "AdPopList",
    component: () =>
      import(/* webpackChunkName: "ad-pop" */ "./pages/AdPop.vue"),
  },
  {
    path: "/ad/pop/edit",
    name: "AdPopEdit",
    component: () =>
      import(/* webpackChunkName: "ad-pop-edit" */ "./pages/AdPopEdit.vue"),
  },

  // 限时抢购
  {
    path: "/sale/discount",
    name: "LimitedDiscountList",
    component: () =>
      import(
        /* webpackChunkName: "limited-discount" */ "./pages/LimitedDiscount.vue"
      ),
  },
  {
    path: "/sale/discountedit",
    name: "LimitedDiscountEdit",
    component: () =>
      import(
        /* webpackChunkName: "limited-discount-edit" */ "./pages/LimitedDiscountEdit.vue"
      ),
  },

  // 分享领券
  {
    path: "/sale/share",
    name: "Share",
    component: () =>
      import(/* webpackChunkName: "Share" */ "./pages/Share.vue"),
  },
  {
    path: "/sale/share/edit",
    name: "ShareEdit",
    component: () =>
      import(/* webpackChunkName: "share-edit" */ "./pages/ShareEdit.vue"),
  },

  // 优惠券
  {
    path: "/sale/coupon",
    name: "Coupon",
    component: () =>
      import(/* webpackChunkName: "coupon" */ "./pages/Coupon.vue"),
  },
  {
    path: "/sale/coupon/edit",
    name: "CouponEdit",
    component: () =>
      import(/* webpackChunkName: "coupon-edit" */ "./pages/CouponEdit.vue"),
  },

  // 悬浮菜单
  {
    path: "/sale/menu",
    name: "Menu",
    component: () => import(/* webpackChunkName: "Menu" */ "./pages/Menu.vue"),
  },

  // 群裂变
  {
    path: "/sale/group",
    name: "Group",
    component: () =>
      import(/* webpackChunkName: "Group" */ "./pages/Group.vue"),
  },

  // 剪贴板
  {
    path: "/sale/clipboard",
    name: "Clipboard",
    component: () =>
      import(/* webpackChunkName: "Clipboard" */ "./pages/Clipboard.vue"),
  },

  // 外链
  {
    path: "/sale/urllink",
    name: "UrlLink",
    component: () =>
      import(/* webpackChunkName: "UrlLink" */ "./pages/UrlLink.vue"),
  },

  // 搜一搜
  {
    path: "/sale/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "Search" */ "./pages/Search.vue"),
  },

  // VIP
  {
    path: "/vip",
    name: "Vip",
    component: () => import(/* webpackChunkName: "vip" */ "./pages/Vip.vue"),
  },

  // 抽奖
  {
    path: "/sale/prize",
    name: "PrizeList",
    component: () =>
      import(/* webpackChunkName: "prize" */ "./pages/Prize.vue"),
  },
  {
    path: "/sale/prize/edit",
    name: "PrizeEdit",
    component: () =>
      import(/* webpackChunkName: "prize-edit" */ "./pages/PrizeEdit.vue"),
  },
  {
    path: "/sale/prize/order",
    name: "PrizeOrder",
    component: () =>
      import(/* webpackChunkName: "prize-order" */ "./pages/PrizeOrder.vue"),
  },

  {
    path: "/code",
    name: "Code",
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "code" */ "./pages/Code.vue"),
  },

  {
    path: "/vproduct",
    name: "VProductList",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "vproduct-list" */ "./pages/VProductList.vue"
      ),
  },
  {
    path: "/ds/login",
    name: "DesignLogin",
    component: () =>
      import(/* webpackChunkName: "design-login" */ "./pages/DesignLogin.vue"),
  },
  {
    path: "*",
    name: "404",
    component: Index,
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
  linkActiveClass: "active",
});

// 登录限制
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        path: "/logout",
      });
    } else {
      next();
    }
  } else {
    next(); // 确保一定要调用 next()
  }
});

export default router;
