import request from "./request";

/**
 * 抽奖
 */

/**
 * 查询拼单
 */
export const list = data => request.get("/prize/", { params: data });

/**
 * 查询抽奖详情
 */
export const detail = id => request.get("/prize/detail", { params: { id } });

/**
 * 创建/编辑抽奖
 */
export const createOrUpdate = data => request.post("/prize/", data);

/**
 * 删除
 */
export const del = id => request.post("/prize/del", { id });

/**
 * 抽奖记录
 */
export const queryOrder = data =>
  request.get("/prize/record", { params: data });
