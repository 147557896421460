var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"选择抽奖活动","visible":_vm.visible,"width":"80%"},on:{"update:visible":function($event){_vm.visible=$event},"ok":function($event){_vm.visible = false;
    _vm.selectedRowKeys = [];},"cancel":function($event){_vm.visible = false;
    _vm.selectedRowKeys = [];}}},[_c('a-table',{ref:"productTable",attrs:{"rowKey":"id","columns":_vm.columns,"data-source":_vm.list,"rowSelection":{
      type: _vm.multiple ? 'checkbox' : 'radio',
      onChange: _vm.onSelectChange,
      selectedRowKeys: _vm.selectedRowKeys
    },"loading":_vm.loading,"locale":{
      emptyText: '请前往「营销互动」 -> 「抽奖活动」，创建抽奖活动'
    }},scopedSlots:_vm._u([{key:"startTime",fn:function(time){return _c('span',{},[_vm._v(_vm._s(_vm._f("formatDate")(time)))])}},{key:"endTime",fn:function(time){return _c('span',{},[_vm._v(_vm._s(_vm._f("formatDate")(time)))])}}])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('div'),_c('div',[(_vm.multiple)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]):_vm._e(),_c('a-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }