<template>
  <!-- 活动选择器 -->
  <a-modal
    title="选择秒杀活动"
    :visible.sync="visible"
    width="80%"
    @ok="
      visible = false;
      selectedRowKeys = [];
    "
    @cancel="
      visible = false;
      selectedRowKeys = [];
    "
  >
    <a-table
      ref="productTable"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :rowSelection="{
        type: multiple ? 'checkbox' : 'radio',
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys
      }"
      :loading="loading"
      :locale="{
        emptyText: '请前往「营销互动」 -> 「秒杀活动」，创建秒杀活动'
      }"
    >
      <span slot="img" slot-scope="url">
        <img class="ts-product-img" :src="url" />
      </span>
      <span slot="startTime" slot-scope="time">{{ time | formatDate }}</span>
      <span slot="endTime" slot-scope="time">{{ time | formatDate }}</span>
    </a-table>

    <div slot="footer" style="display:flex;justify-content: space-between;">
      <div></div>
      <div>
        <a-button v-if="multiple" type="primary" @click="save">保存</a-button>
        <a-button @click="visible = false">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

const LimitedDiscountSvc = require("@/service/limited-discount");

const columns = [
  {
    title: "主图",
    dataIndex: "img",
    key: "img",
    width: 60,
    align: "center",
    scopedSlots: { customRender: "img" }
  },
  {
    title: "商品标题",
    dataIndex: "title",
    key: "title",
    ellipsis: true
  },
  {
    title: "原价",
    align: "right",
    dataIndex: "price",
    key: "price",
    width: 100
  },
  {
    title: "秒杀价",
    align: "right",
    dataIndex: "salePriceRange",
    key: "salePriceRange",
    width: 160
  },
  {
    title: "开始时间",
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    width: 160,
    scopedSlots: { customRender: "startTime" }
  },
  {
    title: "结束时间",
    align: "center",
    dataIndex: "endTime",
    key: "endTime",
    width: 160,
    scopedSlots: { customRender: "endTime" }
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "statusStr",
    key: "statusStr",
    width: 100
  }
];

export default {
  name: "LimitedDiscountDialog",
  data() {
    return {
      form: this.$form.createForm(this, { name: "form_search" }),

      columns,
      loading: false,
      visible: false,

      currentRow: null,
      multipleSelection: [],
      selectedRowKeys: []
    };
  },

  props: {
    multiple: Boolean
  },

  computed: {
    ...mapState({
      discountList: state => state.discountList
    }),
    list() {
      return this.discountList;
    }
  },

  methods: {
    ...mapMutations(["setDiscountList"]),

    async loadData() {
      try {
        this.loading = true;

        const list = await LimitedDiscountSvc.list();
        this.setDiscountList(list);

        this.loading = false;
      } catch (err) {
        console.error(err);
        // this.$message.error("加载活动出错，错误：" + err.message);

        this.loading = false;
      }
    },

    // 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;

      if (this.multiple) {
        this.multipleSelection = selectedRows;
      } else {
        // 自动提交
        this.currentRow = selectedRows[0];
        this.save();
      }
    },

    show() {
      // todo 默认选中
      // this.setSelection(rows);

      this.visible = true;

      this.loadData();
    },

    save() {
      if (this.multiple && this.multipleSelection.length <= 0) {
        this.$message.info("你没有选中任何活动");
        return;
      }

      this.$emit(
        "selected",
        this.multiple ? this.multipleSelection : this.currentRow
      );

      this.visible = false;
      this.selectedRowKeys = [];
    }
  }
};
</script>

<style lang="scss">
.ts-product-img {
  width: 60px;
  max-height: 60px;
  margin: 0 -16px;
}
</style>
