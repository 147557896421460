import request from "./request";

/**
 * 登录
 */
export const login = code => request.get(`/login?code=${code}`);

/**
 * 检查登录有效性
 */
export const check = () => request.get("/u/check");

/**
 * 获取用户绑定码
 */
export const getBindUrl = () => request.get("/u/bindUser");

/**
 * 店铺资料
 */
export const loadStoreInfo = () => request.get("/u/info");

/**
 * 切换服务状态
 */
export const switchStatus = enable => request.post("/u/status", { enable });

/**
 * 提交体验版
 */
export const preview = res => request.post("/u/preview", { res });

/**
 * 发布
 */
export const publish = res => request.post("/u/publish", { res });

/**
 * 获取小程序码
 */
export const getWXACode = path => request.post("/u/qrcode", { path });
