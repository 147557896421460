<template>
  <!-- 活动选择器 -->
  <a-modal
    title="选择分享有礼活动"
    :visible.sync="visible"
    width="80%"
    @ok="
      visible = false;
      selectedRowKeys = [];
    "
    @cancel="
      visible = false;
      selectedRowKeys = [];
    "
  >
    <div class="search-tool">
      <a-form layout="inline" :form="form" @submit="handleSearch">
        <a-form-item label="活动状态">
          <a-select
            v-decorator="['status']"
            placeholder="活动状态"
            style="width:100px;"
            v-model="filters.status"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="未开始">未开始</a-select-option>
            <a-select-option value="进行中">进行中</a-select-option>
            <a-select-option value="已过期">已过期</a-select-option>
            <a-select-option value="已失效">已失效</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="活动标题">
          <a-input
            v-decorator="['title']"
            :maxLength="20"
            allow-clear
            placeholder="输入活动名称"
            v-model="filters.title"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">
            搜索
          </a-button>
          <a-button
            html-type="reset"
            @click="filters = { title: '' }"
            :style="{ marginLeft: '8px' }"
          >
            复位
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table
      ref="productTable"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :rowSelection="{
        type: multiple ? 'checkbox' : 'radio',
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys
      }"
      :loading="loading"
      :locale="{
        emptyText: '请前往「营销互动」 -> 「分享有礼」，创建有礼活动'
      }"
    >
      <span slot="startTime" slot-scope="time">{{ time | formatDate }}</span>
      <span slot="endTime" slot-scope="time">{{ time | formatDate }}</span>
      <span slot="createdAt" slot-scope="time">{{ time | formatDate }}</span>
    </a-table>

    <div slot="footer" style="display:flex;justify-content: space-between;">
      <div></div>
      <div>
        <a-button v-if="multiple" type="primary" @click="save">保存</a-button>
        <a-button @click="visible = false">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
const ShareSvc = require("@/service/share");

const columns = [
  {
    title: "活动标题",
    dataIndex: "title",
    key: "title",
    ellipsis: true
  },
  {
    title: "助力人数",
    align: "right",
    dataIndex: "num",
    key: "num",
    width: 100
  },
  {
    title: "分享券",
    dataIndex: "name",
    key: "name",
    width: 100
  },
  {
    title: "助力券",
    dataIndex: "coupon2Name",
    key: "coupon2Name",
    width: 100
  },
  {
    title: "活动开始时间",
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    width: 160,
    scopedSlots: { customRender: "startTime" }
  },
  {
    title: "活动结束时间",
    align: "center",
    dataIndex: "endTime",
    key: "endTime",
    width: 160,
    scopedSlots: { customRender: "endTime" }
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    key: "status",
    width: 100
  }
];

export default {
  name: "ShareDialog",
  data() {
    return {
      rowList: [],

      form: this.$form.createForm(this, { name: "form_search" }),

      columns,
      loading: false,
      visible: false,

      filters: { title: "", status: "" },

      currentRow: null,
      multipleSelection: [],
      selectedRowKeys: []
    };
  },

  props: {
    multiple: Boolean
  },

  computed: {
    list() {
      const { filters, rowList } = this;
      let { title, status } = filters;
      title = title ? title.toLowerCase() : "";

      let list = rowList;
      if (title) {
        list = list.filter(x => x.title.toLowerCase().indexOf(title) >= 0);
      }
      if (status) {
        list = list.filter(x => x.status === status);
      }

      return list;
    }
  },

  methods: {
    async loadData() {
      try {
        this.loading = true;

        const { res } = await ShareSvc.list({
          page: 1,
          pageSize: 1000
        });
        this.rowList = res;

        this.loading = false;
      } catch (err) {
        console.error(err);
        this.$message.error("加载活动出错，错误：" + err.message);

        this.loading = false;
      }
    },

    // 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;

      if (this.multiple) {
        this.multipleSelection = selectedRows;
      } else {
        // 自动提交
        this.currentRow = selectedRows[0];
        this.save();
      }
    },

    show() {
      // todo 默认选中
      // this.setSelection(rows);

      this.visible = true;

      this.loadData();
    },

    save() {
      if (this.multiple && this.multipleSelection.length <= 0) {
        this.$message.info("你没有选中任何活动");
        return;
      }

      this.$emit(
        "selected",
        this.multiple ? this.multipleSelection : this.currentRow
      );

      this.visible = false;
      this.selectedRowKeys = [];
    },

    // 搜索
    handleSearch(e) {
      e.preventDefault();
      const values = this.form.getFieldsValue();
      let { title } = values;
      this.filters.title = title || "";
    }
  }
};
</script>

<style lang="scss">
.search-tool {
  padding-bottom: 10px;
}
</style>
