<template>
  <div class="app-header">
    <router-link class="logo" to="/index">
      <img class="icn" src="@/assets/logo.png" alt="" />
      <div class="name">{{ title }}</div>
    </router-link>
    <div class="tabs">
      <router-link class="tab" to="/template">模板中心</router-link>

      <router-link class="tab ant-dropdown-link" to="/decoration/home">
        首页装修
      </router-link>
      <router-link class="tab" to="/decoration/category">
        分类页
      </router-link>

      <router-link class="tab" to="/page">页面管理</router-link>

      <a-dropdown placement="bottomCenter">
        <div class="tab">营销互动 <a-icon type="down" /></div>
        <a-menu class="my-menu" slot="overlay">
          <a-menu-item key="0">
            <router-link :to="{ name: 'Client' }">
              <a-icon type="user" /> 顾客管理
            </router-link>
          </a-menu-item>
          <a-divider />
          <a-menu-item key="1">
            <router-link :to="{ name: 'Pin' }">
              <a-icon type="fire" />
              拼团活动</router-link
            >
          </a-menu-item>
          <a-menu-item key="2">
            <router-link :to="{ name: 'PinOrder' }">
              <div style="width:16px;height:16px;display:inline-block" />
              团单管理</router-link
            >
          </a-menu-item>
          <a-divider />
          <a-menu-item key="3">
            <router-link :to="{ name: 'Kan' }">
              <a-icon type="tag" /> 砍价活动
            </router-link>
          </a-menu-item>
          <a-menu-item key="4">
            <router-link :to="{ name: 'KanOrder' }">
              <div style="width:16px;height:16px;display:inline-block" />
              砍价明细</router-link
            >
          </a-menu-item>
          <a-divider />
          <a-menu-item key="5">
            <router-link :to="{ name: 'LimitedDiscountList' }">
              <a-icon type="thunderbolt" /> 限时秒杀
            </router-link>
          </a-menu-item>
          <a-divider />
          <a-menu-item key="6">
            <router-link :to="{ name: 'PrizeList' }">
              <a-icon type="gift" /> 幸运抽奖
            </router-link>
          </a-menu-item>
          <a-menu-item key="7">
            <router-link :to="{ name: 'PrizeOrder' }">
              <div style="width:16px;height:16px;display:inline-block" />
              中奖记录</router-link
            >
          </a-menu-item>
          <a-divider />
          <a-menu-item key="8">
            <router-link :to="{ name: 'Coupon' }">
              <a-icon type="tags" /> 优惠券
            </router-link>
          </a-menu-item>
          <a-menu-item key="9">
            <router-link :to="{ name: 'AdPopList' }">
              <a-icon type="fund" /> 弹窗广告</router-link
            >
          </a-menu-item>
          <a-menu-item key="10">
            <router-link :to="{ name: 'Share' }">
              <a-icon type="red-envelope" /> 分享有礼
            </router-link>
          </a-menu-item>
          <a-menu-item key="11">
            <router-link :to="{ name: 'Menu' }">
              <a-icon type="menu" /> 悬浮菜单
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <router-link v-if="role.designer" class="tab" to="/vproduct">
        虚拟商品
      </router-link>

      <router-link class="tab" to="/vip">VIP</router-link>
      <router-link class="tab" to="/help">帮助</router-link>
    </div>
    <div v-if="!role.designer" class="service">
      <a-tag color="red">
        {{ serviceName }}
      </a-tag>
      <a-tooltip placement="bottomRight">
        <template slot="title">
          购买日期从试用结束开始计算，请放心使用
        </template>
        <div class="expire">{{ expireTips }}</div>
      </a-tooltip>
      <a
        v-if="expireDays <= 15"
        href="https://shop.weixin.qq.com"
        target="_blank"
        class="renew"
      >
        点此续费
      </a>
    </div>
    <div v-if="!role.designer" class="decorate-status">
      <a @click="switchStatus" class="stopService">{{
        decorationEnabled ? "停用服务" : "启用服务"
      }}</a>
    </div>
    <div class="pending"></div>
    <div v-if="isDesignPage" class="opts">
      <a-button
        :loading="previewLoading"
        @click="preview"
        type="button"
        class="opt"
      >
        <span>手机预览</span>
      </a-button>
      <a-tooltip placement="bottomRight">
        <template slot="title">
          如果没有生效，请点击「停用服务」，再点击「启用服务」，管理员微信收到启用通知并点击确认即可。
        </template>
        <a-button
          :loading="publishLoading"
          @click="publish"
          type="primary"
          class="opt"
        >
          发布上线
        </a-button>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const moment = require("moment");

import { isDesigner } from "@/service/auth";

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,

      isDesignPage: false
    };
  },

  computed: {
    ...mapState({
      service: state => state.service,
      decorationEnabled: state => state.decoration.enable,

      previewLoading: state => state.button.previewLoading,
      publishLoading: state => state.button.publishLoading
    }),

    role() {
      return {
        designer: isDesigner()
      };
    },

    serviceName() {
      return this.service.code == "free" ? "试用版" : "专业版";
    },
    expireDays() {
      return moment(this.service.expire).diff(moment(), "days");
    },
    expireTips() {
      if (this.expireDays == 0) {
        return `今天到期`;
      } else if (this.expireDays > 0) {
        return `剩余 ${this.expireDays} 天`;
      } else {
        return "已到期";
      }
    }
  },

  async mounted() {
    this.isDesignPage = this.$route.name == "DecorationHome";

    // 过期提醒
    if (this.expireDays < 0) {
      this.$message.info("您的服务已到期，请续费");
      this.$router.replace("/logout");
    }
  },

  methods: {
    async switchStatus() {
      this.$root.$emit("switch-status");
    },
    async preview() {
      this.$root.$emit("preview");
    },
    async publish() {
      this.$root.$emit("publish");
    }
  }
};
</script>

<style lang="scss">
.app-header {
  width: 100%;
  display: flex;
  align-items: center;

  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 0 0 auto;

    .icn {
      width: 40px;
      height: 40px;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
      margin-left: 8px;
      font-family: "PingFangSC-Semibold";
    }
  }

  .tabs {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    .tab {
      font-size: 16px;
      margin-left: 4rem;
      color: rgba(0, 0, 0, 0.9);

      &.ad {
        display: flex;
        align-items: center;
        margin-left: 10px;
        padding: 2px 4px;
        border-radius: 5px;
        background: #4320e1;
        color: #fff;

        i {
          font-size: 21px;
          margin-right: 2px;
        }

        .txt {
          display: flex;
          flex-direction: column;
          font-size: 11px;
          align-items: center;
          line-height: 1.2em;
        }
      }
    }

    .tab.active {
      color: var(--primary-color);
      font-weight: 600;
    }
  }

  .service {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-left: 6rem;
    flex: 0 0 auto;

    .renew {
      color: var(--primary-color);
      font-weight: bold;
      text-decoration: underline;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .pending {
    flex: 1 1 auto;
  }

  .opts {
    margin-left: 6rem;
    flex: 0 0 auto;

    .opt {
      margin-left: 12px;
      border-radius: 6px;
      font-weight: 600;
      border: solid 1px rgba(0, 0, 0, 0.1);
    }

    .opt.primary {
      border-color: #ff6246;
    }
  }

  .ant-dropdown-trigger {
    cursor: pointer;
  }
}

.decorate-status {
  display: flex;
  align-items: center;
  margin-left: 12px;

  .stopService {
    font-size: 12px;
    color: #555555;
    cursor: pointer;
    flex: 0 0 auto;
    line-height: 1;
    text-decoration: underline;
  }
}

.qrcode {
  width: 300px;
  height: 300px;
}

.guide {
  width: 300px;
  margin: 10px 0;
}

.my-menu {
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    padding: 10px 15px;
    font-size: 16px;
    // font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
  }
}

.ant-dropdown-menu {
  .ant-divider-horizontal {
    margin: 0;
  }
}
.ant-dropdown-menu .ant-divider-horizontal {
  width: 180px;
}
</style>
