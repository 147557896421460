import Vue from "vue";
import Vuex from "vuex";

import { message } from "ant-design-vue";

Vue.use(Vuex);

import { randomKey } from "@/utils/tool";

export default new Vuex.Store({
  state: {
    // 组件设计
    design: {
      // 组件列表
      list: [],
      // 固定组件
      fix: {},
      // 当前组件
      current: null
    },
    // 商品
    productList: [],
    // 带货商品
    productList2: [],
    // 优惠劵
    couponList: [],
    // 店铺分类
    categoryList: [],
    // 页面
    pageList: [],
    // 拼团
    pinList: [],
    // 砍价
    kanList: [],
    // 秒杀
    discountList: [],
    // 分享有礼
    shareList: [],
    // 抽奖
    prizeList: [],

    store: {},
    service: {},
    decoration: { enable: false },
    func: [], // 权限集

    // 按钮状态
    button: {
      previewLoading: false, // 预览中
      publishLoading: false // 发布中
    },

    // 编辑变更提示
    oldDesignData: ""
  },
  getters: {
    /**
     * 当前组件
     */
    currentElement(state) {
      const { list, fix, current } = state.design;
      if (current === null) {
        return null;
      }

      if (typeof current === "string") {
        return fix[current];
      }

      return list[current];
    }
  },
  mutations: {
    /**
     * 切换预览按钮状态
     */
    togglePreviewLoading(state, status) {
      state.button.previewLoading = status;
    },

    /**
     * 切换发布按钮状态
     */
    togglePublishLoading(state, status) {
      state.button.publishLoading = status;
    },

    /**
     * 更新编辑数据
     */
    updateOldDesignData(state, res) {
      if (res) {
        state.oldDesignData = JSON.stringify({
          list: res.list,
          fix: res.fix
        });
      } else {
        state.oldDesignData = "";
      }
    },

    /**
     * 选中组件
     */
    selectElement(state, index) {
      state.design.current = index;
    },

    /**
     * 选中固定组件
     */
    selectFixElement(state, cls) {
      state.design.current = cls;
    },

    /**
     * 复制组件
     */
    copyElement(state, index) {
      const list = state.design.list;
      let data = JSON.parse(JSON.stringify(list[index]));

      // 生成新key
      data.key = randomKey();

      state.design.list = [...list.slice(0, index), data, ...list.slice(index)];
      state.design.current = index + 1;
    },

    /**
     * 追加组件
     */
    addElement(state, data) {
      const cls = data.cls;

      // 固定位置
      if (data.fix) {
        if (state.design.fix[cls]) {
          message.info("只能添加一个");
          return;
        }

        state.design.fix = { ...state.design.fix, [cls]: data };
        return;
      }

      const list = state.design.list;
      const len = list.length;

      // 仅限一个的组件：全部商品
      const checkCls = ["product", "cat"];
      if (checkCls.includes(cls)) {
        if (list.filter(x => x.cls === cls).length > 0) {
          message.info("只能添加一个");
          return;
        }
      }

      // 全部商品始终在最后
      const idxOfProduct = list.findIndex(x => checkCls.includes(x.cls));
      if (idxOfProduct > -1) {
        const product = list[idxOfProduct];
        state.design.list = [...list.slice(0, idxOfProduct), data, product];
        state.design.current = idxOfProduct;
      } else {
        state.design.list = [...list, data];
        state.design.current = len;
      }
    },

    /**
     * 删除组件
     */
    deleteElement(state, index) {
      const { list } = state.design;

      if (typeof index === "string") {
        /* eslint-disable no-unused-vars */

        const { live, ...rest } = state.design.fix;
        state.design.fix = rest;
        return;

        /* eslint-enable no-unused-vars */
      }

      state.design.list = [...list.slice(0, index), ...list.slice(index + 1)];
      state.design.current = null;
    },

    /**
     * 排序
     */
    sortList(state, values) {
      state.design.list = values;
    },

    /**
     * 以下为后台API加载数据
     */

    // 加载装修数据
    setDesignData(state, data) {
      const { list, fix } = data || {};
      state.design.list = list || [];
      state.design.fix = fix || {};
      state.design.current = null;
    },

    setStoreInfo(state, data) {
      state.store = data.store; // 店铺资料
      state.service = data.service; // 服务期限
      state.decoration = data.decoration; // 装修状态
      state.func = data.func; // 权限集
    },

    setCouponList(state, data) {
      state.couponList = data || [];
    },

    setProductList(state, data) {
      state.productList = data || [];
    },

    setThirdProductList(state, data) {
      state.productList2 = data || [];
    },

    setCategoryList(state, data) {
      state.categoryList = data || [];
    },

    setPageList(state, data) {
      state.pageList = data || [];
    },

    setPinList(state, data) {
      state.pinList = data || [];
    },

    setKanList(state, data) {
      state.kanList = data || [];
    },

    setDiscountList(state, data) {
      state.discountList = data || [];
    },

    setShareList(state, data) {
      state.shareList = data || [];
    },

    setPrizeList(state, data) {
      state.prizeList = data || [];
    },

    setDecorationStatus(state, enable) {
      state.decoration.enable = enable;
    }
  },
  actions: {},
  modules: {}
});
