<template>
  <a-modal
    :visible.sync="visible"
    title="编辑图片热区"
    :width="900"
    :footer="null"
    @change="visible = false"
  >
    <a-form class="hotzone-dialog">
      <a-row>
        <a-col :span="14">
          <div class="hotzone-wrap">
            <hotzone
              v-if="res.img"
              id="hotzone"
              ref="hotzone"
              :key="key"
              :image="res.img"
              :zonesInit="zones"
              @add="handleAdd"
              @remove="handleRemove"
              @change="handleChange"
            />
            <a-empty
              v-else
              description="请先上传图片"
              style="margin-top: 100px;"
            />
          </div>

          <div class="tool">
            <a-upload
              class=""
              :data="{ index: 0 }"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <a-button type="primary">
                <a-icon type="upload" /> 更换图片</a-button
              >
            </a-upload>
            <a-button v-if="res.rows.length" @click="clearZone">
              清空热区
            </a-button>
          </div>
        </a-col>
        <a-col :span="10">
          <template v-if="res.rows.length > 0">
            <div class="link-row">
              <a-row :gutter="5">
                <a-col :span="2"> </a-col>
                <a-col :span="8">跳转</a-col>
                <a-col :span="14">地址</a-col>
              </a-row>
            </div>
            <div v-for="(row, index) in res.rows" :key="index" class="link-row">
              <a-row :gutter="5">
                <a-col :span="2">
                  <div class="seq">{{ index + 1 }}</div>
                </a-col>
                <a-col :span="8">
                  <a-select v-model="row.target" placeholder="请选择">
                    <a-select-option
                      v-for="t in targetList"
                      :key="t.id"
                      :value="t.id"
                      >{{ t.name }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="14">
                  <!-- 商品 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="row.target == 1"
                    v-model="row.productId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="p in productList"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 带货商品 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="row.target == 6"
                    v-model="row.productId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="p in productList2"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 优惠券 -->
                  <a-select
                    v-if="row.target == 2"
                    v-model="row.couponId"
                    placeholder="请选择"
                    notFoundContent="请在小商店后台创建优惠券"
                  >
                    <a-select-option
                      v-for="c in couponList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.name }}
                    </a-select-option>
                  </a-select>

                  <!-- 分类 -->
                  <a-tree-select
                    v-if="row.target == 3"
                    v-model="row.categoryId"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="categoryTree"
                    placeholder="请选择"
                    notFoundContent="请在小商店后台创建店铺分类"
                    tree-default-expand-all
                  >
                  </a-tree-select>

                  <!-- 页面 -->
                  <a-select
                    v-if="row.target == 4"
                    v-model="row.pageId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in pageList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.name }}
                    </a-select-option>
                  </a-select>

                  <!-- 拼团 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="row.target == 7"
                    v-model="row.pinId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in pinList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 砍价 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="row.target == 8"
                    v-model="row.kanId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in kanList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 分享有礼 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="row.target == 9"
                    v-model="row.shareId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in shareList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 抽奖 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="row.target == 14"
                    v-model="row.prizeId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in prizeList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 招募推荐官 -->
                  <a-input
                    v-if="row.target == 11"
                    v-model="row.planId"
                    :max-length="10"
                    type="number"
                    placeholder="请复制招募计划 planId"
                  />

                  <!-- 小程序 -->
                  <a-input
                    v-if="row.target == 15"
                    v-model="row.minapp"
                    :max-length="255"
                    placeholder="appid#query 或 #小程序链接"
                  />

                  <!-- 网页 -->
                  <a-input
                    v-if="row.target == 12"
                    v-model="row.url"
                    :max-length="200"
                    placeholder="请输入网页地址"
                  />

                  <!-- 拨打电话 -->
                  <a-input
                    v-if="row.target == 20"
                    v-model="row.tel"
                    :max-length="100"
                    placeholder="请输入电话号码"
                  />
                  <!-- 复制文本 -->
                  <a-input
                    v-if="row.target == 21"
                    v-model="row.text"
                    :max-length="100"
                    placeholder="请输入文本"
                  />
                  <a-upload
                    v-if="row.target == 22"
                    :data="{ index: 100+index }"
                    :action="UPLOAD_URL"
                    :headers="{ t: getToken }"
                    withCredentials
                    accept="image/*"
                    :showUploadList="false"
                    @change="handleUpload"
                    :before-upload="beforeUpload"
                    >
                    <img
                      v-if="row.qrcode"
                      class="upload-image-small"
                      :src="row.qrcode"
                    />
                    <a-button-group>
                      <a-button>
                        <a-icon type="upload" />
                        {{ row.qrcode ? "更换" : "上传" }}图片
                      </a-button>
                    </a-button-group>
                  </a-upload>
                </a-col>
              </a-row>
            </div>
          </template>
          <div v-else class="tips">
            请用鼠标在图片上拖拽出区域，<br />
            然后在这里选择跳转链接
          </div>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import hotzone from "vue2-hotzone";

import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";
import targetMixin from "@/mixins/target";
import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import { randomKey } from "@/utils/tool";

export default {
  name: "HotzoneDialog",

  data() {
    return {
      key: randomKey(),
      visible: false
    };
  },

  model: {
    prop: "res",
    event: "change"
  },

  props: {
    res: Object
  },

  mixins: [
    uploadMixin,
    textMixin,
    targetMixin,

    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin
  ],

  computed: {
    zones() {
      return this.res.rows.map(x => x.zone);
    }
  },

  mounted() {},

  methods: {
    show() {
      this.visible = true;
    },

    handleAdd(zone) {
      this.res.rows.push({
        zone,

        key: randomKey(),

        target: 1,
        productId: null,
        couponId: null,
        categoryId: null,
        pageId: null
      });
    },
    handleRemove(index) {
      if (this.res.rows[index].zone.heightPer === 0) {
        this.$message.info("请框选大一点范围");
      }

      this.res.rows.splice(index, 1);
    },
    handleChange(zones) {
      for (let index = 0; index < zones.length; index++) {
        let row = this.res.rows[index];
        if (row) {
          row.zone = zones[index];
        }
      }
    },
    clearZone() {
      this.$refs.hotzone.zones = [];
      this.res.rows = [];
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url, index } = data;

        if(index === 0){
          this.res.img = url;
        } else {
          const idx = index-100
          const val = {...this.res.rows[idx], qrcode: url}
          this.$set(this.res.rows, idx, val)
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    }
  },

  components: {
    hotzone
  }
};
</script>

<style lang="scss">
.hotzone-dialog {
  .hotzone-wrap {
    min-height: 400px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  .tool {
    text-align: center;

    .ant-btn {
      margin-right: 8px;
    }
  }

  .link-row {
    margin-bottom: 5px;
  }
  .seq {
    background: #000;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px 0 0 4px;
  }

  .tips {
    padding: 0 40px;
    margin-top: 200px;
    text-align: center;
  }
}
</style>
