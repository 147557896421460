/**
 * 文本属性
 */
let fontSizeList = [];
for (let i = 10; i <= 40; i++) {
  fontSizeList.push({
    id: `${i}px`,
    name: `${i}px`
  });
}

export default {
  data() {
    return {
      horizonLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },

      fontSizeList,
      fontWeightList: [
        { id: "lighter", name: "细" },
        { id: "normal", name: "常规" },
        { id: "bold", name: "加粗" }
      ]
    };
  }
};
