var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"选择商品","visible":_vm.visible,"width":"80%"},on:{"update:visible":function($event){_vm.visible=$event},"ok":function($event){_vm.visible = false;
    _vm.selectedRowKeys = [];},"cancel":function($event){_vm.visible = false;
    _vm.selectedRowKeys = [];}}},[_c('div',{staticClass:"search-tool"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form},on:{"submit":_vm.handleSearch}},[(!_vm.onlySelfProduct)?_c('a-form-model-item',{attrs:{"label":"商品库"}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("自营商品")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v("带货商品")])],1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-tree-select',{staticStyle:{"min-width":"180px"},attrs:{"show-search":"","allow-clear":"","dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.categoryTree,"placeholder":"请选择","tree-default-expand-all":""},model:{value:(_vm.filters.cid),callback:function ($$v) {_vm.$set(_vm.filters, "cid", $$v)},expression:"filters.cid"}})],1),_c('a-form-item',{attrs:{"label":"商品标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title']),expression:"['title']"}],attrs:{"maxLength":20,"allow-clear":"","placeholder":"输入商品名称"},model:{value:(_vm.filters.title),callback:function ($$v) {_vm.$set(_vm.filters, "title", $$v)},expression:"filters.title"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 搜索 ")]),_c('a-button',{style:({ marginLeft: '8px' }),attrs:{"html-type":"reset"},on:{"click":function($event){_vm.filters = { title: '', cid: '' }}}},[_vm._v(" 复位 ")])],1)],1)],1),_c('a-table',{ref:"productTable",attrs:{"rowKey":"id","columns":_vm.columns,"data-source":_vm.list,"rowSelection":{
      type: _vm.multiple ? 'checkbox' : 'radio',
      onChange: _vm.onSelectChange,
      selectedRowKeys: _vm.selectedRowKeys
    },"loading":_vm.loading},scopedSlots:_vm._u([{key:"img",fn:function(url){return _c('img',{staticStyle:{"width":"78px","height":"78px"},attrs:{"src":url}})}},{key:"price",fn:function(text){return _c('span',{},[_vm._v("￥"+_vm._s(text))])}}])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('div',[_c('a-button',{attrs:{"loading":_vm.loading},on:{"click":_vm.loadData}},[_c('a-icon',{attrs:{"type":"sync"}}),_vm._v(" 同步商品库")],1)],1),_c('div',[(_vm.multiple)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]):_vm._e(),_c('a-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }