<template>
  <div class="app-index"><a-spin /></div>
</template>

<script>
const PageSvc = require("@/service/page");

export default {
  async created() {
    try {
      const list = await PageSvc.list();
      if (list && list.length > 0) {
        // 有数据时跳转到装修
        this.$router.replace("/decoration/home");
      } else {
        // 无数据时跳转到模板
        this.$router.replace("/template");
      }
    } catch (err) {
      console.error(err);
      this.$message.error("登录已过期");

      // 错误处理
      this.$router.replace("/logout");
    }
  }
};
</script>

<style>
.app-index {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
