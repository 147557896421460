import request from "./request";

/**
 * 砍价
 */

/**
 * 查询砍价
 */
export const list = data => request.get("/kan/", { params: data });

/**
 * 查询详情
 */
export const detail = id => request.get("/kan/detail", { params: { id } });

/**
 * 创建/编辑砍价
 */
export const createOrUpdate = data => request.post("/kan/", data);

/**
 * 切换状态
 */
export const updateStatus = id => request.post("/kan/status", { id });

/**
 * 删除
 */
export const del = id => request.post("/kan/del", { id });

/**
 * 查询砍价单
 */
export const queryOrder = data => request.get("/kan/order", { params: data });

/**
 * 查询砍价用户
 */
export const queryOrderUser = data => request.get("/kan/order-user", { params: data });

/**
 * 查询规则
 */
export const getRule = () => request.get("/kan/rule");

/**
 * 编辑规则
 */
export const updateRule = data => request.post("/kan/rule", data);
