<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />

      <a-modal
        :visible="previewDialogVisible"
        :confirm-loading="publishLoading"
        okText="发布上线"
        @ok="publish"
        @cancel="previewDialogVisible = false"
        :bodyStyle="{
          'text-align': 'center'
        }"
      >
        <div class="title">
          扫描二维码进行预览，预览后请记得发布上线
        </div>
        <img :src="previewQrCode" class="qrcode" />
        <div>
          <a-icon type="info-circle" /> 提示：体验版仅用于测试用途，用户无法查看
        </div>
      </a-modal>

      <a-modal
        :visible="publishDialogVisible"
        @ok="showStoreDialog"
        @cancel="publishDialogVisible = false"
        cancel-text="关闭"
        ok-text="查看店铺"
        :bodyStyle="{
          'text-align': 'center'
        }"
      >
        <div
          class="title"
          style="font-size: 20px; font-weight: bold; margin-top: 0px"
        >
          还差一步，确认启用后生效
        </div>
        <div class="tip">
          仅初次发布需要确认启用，后续更新时点发布上线即可生效
        </div>
        <img class="guide" src="@/assets/publish-tips.jpg" />
        <div>
          <a-icon type="info-circle" />
          提示：管理员微信会收到启用通知，点击确认启用才会生效。
        </div>
      </a-modal>

      <!-- 分享二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="storeDialogVisible"
        @cancel="storeDialogVisible = false"
        class="share-dialog-wrap"
      >
        <div class="title">
          扫码查看店铺
        </div>
        <img :src="store.qrcode" class="qrcode" />
        <div><a-icon type="info-circle" /> 提示：右键保存小程序码即可分享</div>
      </a-modal>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";

import { mapState, mapMutations } from "vuex";

const UserSvc = require("@/service/user");

export default {
  data() {
    return {
      locale: zhCN,

      previewDialogVisible: false,
      previewQrCode: "",

      publishDialogVisible: false,

      storeDialogVisible: false
    };
  },

  computed: {
    ...mapState({
      store: state => state.store,

      previewLoading: state => state.button.previewLoading,
      publishLoading: state => state.button.publishLoading,

      decorationEnabled: state => state.decoration.enable
    })
  },

  async created() {},

  async mounted() {
    this.$root.$on("switch-status", this.switchStatus);
    this.$root.$on("preview", this.preview);
    this.$root.$on("publish", this.publish);
    this.$root.$on("auth", this.auth);
  },

  methods: {
    ...mapMutations([
      "setDecorationStatus",
      "togglePreviewLoading",
      "togglePublishLoading"
    ]),

    switchStatus() {
      const enable = !this.decorationEnabled;

      if (!enable) {
        this.$confirm({
          title: "操作提示",
          content: "确认停用吗？停用后在手机端确认。",

          onOk: () => {
            this._switchStatus(enable);
          },
          onCancel() {}
        });
      } else {
        this._switchStatus(enable);
      }
    },

    async _switchStatus(enable) {
      try {
        await UserSvc.switchStatus(enable);
        this.setDecorationStatus(enable);

        this.$message.info("装修服务" + enable ? "已启用" : "已停用");
      } catch (err) {
        console.error(err);
        this.$message.error("切换状态失败，错误：" + err.message);
      }
    },

    async preview() {
      this.togglePreviewLoading(true);

      try {
        const { list, fix } = this.$store.state.design;
        const res = await UserSvc.preview(JSON.stringify({ list, fix }));

        // 二维码 base64
        this.previewQrCode = "data:image/png;base64," + res.b64;

        this.togglePreviewLoading(false);
        this.previewDialogVisible = true;
      } catch (err) {
        console.error(err);

        this.togglePreviewLoading(false);
        this.$message.error("部署失败，错误：" + err.message);
      }
    },

    async publish() {
      this.togglePublishLoading(true);

      try {
        const { list, fix } = this.$store.state.design;
        await UserSvc.publish(JSON.stringify({ list, fix }));

        // 广播首页已保存
        this.$root.$emit("home-data-saved");

        this.togglePublishLoading(false);
        this.previewDialogVisible = false;

        if (!this.decorationEnabled) {
          // 已经自动上线
          this.setDecorationStatus(true);

          this.publishDialogVisible = true;
        } else {
          this.$message.info("已发布");
        }
      } catch (err) {
        console.error(err);

        this.togglePublishLoading(false);
        this.$message.error("发布失败，错误：" + err.message);
      }
    },

    showStoreDialog() {
      this.publishDialogVisible = false;
      this.storeDialogVisible = true;
    },

    // 重新授权
    async auth() {
      window.open("https://api.xinsailei.com/msa/wx");

      this.$confirm({
        title: "授权提示",
        content: () => (
          <div>
            请使用微信扫描二维码，选择小商店确认授权，授权后需要重新登录。
          </div>
        ),
        okText: "重新登录",
        onOk: async () => {
          window.location.href = "https://shop.weixin.qq.com";
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style lang="scss">
.share-dialog-wrap {
  text-align: center;

  .title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
  }
  .row {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .label {
      width: 60px;
    }
  }
}

.app-header {
  .page-header {
    width: 100%;

    padding: 16px 0px;

    .ant-page-header-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// 当前选中动态边框
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

.upload-image-small {
  width: 40px;
  height: 32px;
  margin-right: 10px;
}
</style>
