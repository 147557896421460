export default {
  data() {
    return {
      targetList: [
        { id: 1, name: "自营商品" },
        { id: 6, name: "带货商品" },
        { id: 2, name: "优惠券" },
        { id: 3, name: "分类" },

        { id: 7, name: "拼团" },
        { id: 8, name: "砍价" },

        { id: 4, name: "页面" },
        { id: 5, name: "直播" },

        { id: 14, name: "抽奖" },
        { id: 9, name: "分享有礼" },
        { id: 10, name: "会员中心" },

        // { id: 11, name: "招推荐官" },
        { id: 13, name: "群裂变" },
        { id: 15, name: "小程序" },

        // { id: 12, name: "网页" },

        { id: 20, name: "拨打电话" },
        { id: 21, name: "复制文本" },
        { id: 22, name: "弹二维码" },

        { id: 0, name: "无跳转" }
      ]
    };
  }
};
