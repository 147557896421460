export function loggedIn() {
  return !!localStorage.getItem("token");
}

export function userLogin(token) {
  localStorage.setItem("token", token);

  // 自动清理设计模式
  localStorage.removeItem("_ds");
}

export function getToken() {
  return localStorage.getItem("token");
}

export function logout() {
  localStorage.removeItem("token");

  // 自动清理设计模式
  localStorage.removeItem("_ds");
}

/**
 * 设计代理模式
 */

export function isDesigner() {
  // 1: 模板设计师，其它: 代理
  return localStorage.getItem("_ds") == "1";
}

export function designLogin(token, id) {
  localStorage.setItem("token", token);
  localStorage.setItem("_ds", id);
}
