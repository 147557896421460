<template>
  <div class="app-login"><a-spin /></div>
</template>

<script>
import { userLogin } from "@/service/auth";
const UserSvc = require("@/service/user");

export default {
  async created() {
    try {
      // 登录
      const { code } = this.$route.query || {};

      if (!code) {
        this.$router.replace("/logout");
        return;
      }

      const { token } = await UserSvc.login(code);
      userLogin(token);

      // 跳转首页
      this.$router.replace("/decoration/home");
    } catch (err) {
      console.error(err);
      this.$message.error("登录失败，错误：" + err.message);

      this.$router.replace("/logout");
    }
  },

  methods: {}
};
</script>

<style>
.app-login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
