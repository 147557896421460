import request from "./request";

/**
 * 拼团
 */

/**
 * 查询拼单
 */
export const list = data => request.get("/pin/", { params: data });

/**
 * 查询拼单详情
 */
export const detail = id => request.get("/pin/detail", { params: { id } });

/**
 * 创建/编辑拼单
 */
export const createOrUpdate = data => request.post("/pin/", data);

/**
 * 切换状态
 */
export const updateStatus = id => request.post("/pin/status", { id });

/**
 * 删除
 */
export const del = id => request.post("/pin/del", { id });

/**
 * 查询团单
 */
export const queryOrder = data => request.get("/pin/order", { params: data });

/**
 * 查询团单用户
 */
export const queryOrderUser = data =>
         request.get("/pin/order-user", { params: data });

/**
 * 查询规则
 */
export const getRule = () => request.get("/pin/rule");

/**
 * 编辑规则
 */
export const updateRule = data => request.post("/pin/rule", data);
