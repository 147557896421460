<template>
  <div class="basic-layout">
    <header>
      <slot name="header">
      </slot>
      <VHeader v-if="!$slots.header"/>
    </header>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
import VHeader from "./header.vue";

import { mapMutations } from "vuex";

const UserSvc = require("@/service/user");

export default {
  async created() {
    // 登录验证
    try {
      await UserSvc.check();
    } catch (err) {
      console.error(err);
      this.$message.error(err.message);

      // 错误处理
      this.$router.replace("/login");
    }

    // 加载店铺信息
    try {
      const store = await UserSvc.loadStoreInfo();
      this.setStoreInfo(store);
    } catch (err) {
      console.error(err);
      this.$message.error("加载店铺信息失败，请刷新页面");
    }
  },

  methods: {
    ...mapMutations(["setStoreInfo"])
  },

  components: { VHeader }
};
</script>

<style lang="scss">
:root {
  box-sizing: border-box;
  font-size: 0.78125vw;
  color: rgba(0, 0, 0, 0.9);

  --header-height: 60px;
  --primary-color: #1890ff;
}

.basic-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  > header {
    padding: 0px 20px;
    flex: 0 0 auto;
    height: var(--header-height);
    display: flex;
    align-items: center;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.04);
    border-bottom: solid 1px rgba(0, 0, 0, 0.04);
    background-color: rgb(255, 255, 255);
  }

  > body {
    flex: 1 1 auto;
    overflow-y: scroll;
  }
}
</style>
