import request from "./request";

/**
 * 秒杀
 */

/**
 * 查询秒杀活动
 */
export const list = (status = 0) => request.get("/ms/", { params: { status } });

/**
 * 创建秒杀活动
 */
export const create = data => request.post("/ms/", data);

/**
 * 变更状态
 */
export const updateStatus = (id, status) =>
  request.post("/ms/status", { id, status });
