import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      productList: state => state.productList,
      productList2: state => state.productList2,
      pinList: state => state.pinList,
      kanList: state => state.kanList,
      shareList: state => state.shareList,
      prizeList: state => state.prizeList
    })
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
