import request from "./request";

/**
 * 加载首页装修数据
 */
export const loadDecorationData = () => request.get("/d/home");

/**
 * 优惠劵
 */
export const loadCouponList = () => request.get("/d/coupon");

/**
 * 商品库
 */
export const loadProductList = () => request.get("/d/product");

/**
 * 带货商品库
 */
export const loadThirdProductList = () => request.get("/d/product-3rd");

/**
 * 店铺分类
 */
export const loadShopCategoryList = () => request.get("/d/category");
