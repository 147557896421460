import { UPLOAD_URL } from "@/service/request";
import { getToken } from "@/service/auth";

const LIMIT_M = 2; // 默认不超过2M

export default {
  data() {
    return {
      UPLOAD_URL
    };
  },

  computed: {
    getToken
  },

  methods: {
    beforeUpload(file) {
      const limit = this.LIMIT_M ? this.LIMIT_M : LIMIT_M;
      const isLt2M = file.size / 1024 / 1024 < limit;
      if (!isLt2M) {
        this.$message.error(`文件大小不能超过 ${limit}M!`);
      }
      return isLt2M;
    },

    /**
     * 删除背景图片
     */
    removeBackImage() {
      this.res.backgroundImage = "";
      this.res.style.backgroundImage = "";
    }
  }
};
